import React from "react";
import styled from "styled-components";

export default React.forwardRef(({ className, id, ...props }, ref) => (
  <Container className={className} id={id} ref={ref}>
    <Wrapper>{props.children}</Wrapper>
  </Container>
));

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
`;
