import React from "react";
import { TimelineLite } from "gsap";

import Box from "../../components/box";
import { Wrapper } from "./components";
import { Title, SmallTitle, BodyText } from "../../components/text";
import FullHeightBox from "../../components/full-height-box";

import useEntryAnimation from "../../utility/useEntryAnimation";
import useScrollAnimation from "../../utility/useScrollAnimation";

function Footer() {
  const $container = useScrollAnimation(() => {
    const tl = new TimelineLite();

    tl.add(() => {
      animateIn();
    });

    return tl;
  });

  const [animateIn] = useEntryAnimation($container);

  return (
    <FullHeightBox
      id="contacts"
      ref={$container}
      css={`
        position: relative;
        overflow: hidden;
      `}
    >
      <div
        css={`
          @media screen and (min-width: 1280px) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            img {
              display: block;
              margin: 0;
              width: 100%;
              object-fit: cover;
              opacity: 0.2;
            }
          }
        `}
      >
        <img
          loading="lazy"
          alt="Pictures of Primis @Bergamo"
          src="https://primisgroup.imgix.net/pictures/bg.jpg?q=24&auto=format&w=1280"
        />
      </div>

      <Wrapper>
        <Box my={5}>
          <SmallTitle>Contacts</SmallTitle>
          <Title data-reveal fontWeight={300}>
            hello@primisgroup.com
          </Title>
          <Title data-reveal fontWeight={300}>
            +39 035 074 0416
          </Title>
        </Box>

        <Box my={5}>
          <BodyText data-reveal fontWeight={600}>
            Milan
          </BodyText>

          <BodyText data-reveal="lines" color="mute">
            Via Vigevano, 35 <br />
            20162 Milano MI
            <br />
            Italy <br /> <br />
          </BodyText>
        </Box>

        <Box my={5}>
          <BodyText data-reveal fontWeight={600}>
            Bergamo
          </BodyText>

          <BodyText color="mute" data-reveal="lines">
            Via Autostrada, 32 <br />
            24126 Bergamo BG <br />
            Italy <br /> <br />
          </BodyText>
        </Box>

        <Box my={5}>
          <BodyText color="mute" data-reveal="lines">
            <SmallTitle>Other Languages</SmallTitle>
            <a
              css={`
                text-decoration: none;
                font-weight: bold;
                margin-right: 1rem;
              `}
              href="https://primisgroup.com"
            >
              EN
            </a>
            <a
              css={`
                text-decoration: none;
                font-weight: bold;
                margin-right: 1rem;
              `}
              href="https://it.primisgroup.com"
            >
              IT
            </a>{" "}
            <a
              css={`
                text-decoration: none;
                font-weight: bold;
                margin-right: 1rem;
              `}
              href="https://fr.primisgroup.com"
            >
              FR
            </a>{" "}
            <a
              css={`
                text-decoration: none;
                font-weight: bold;
                margin-right: 1rem;
              `}
              href="https://ar.primisgroup.com"
            >
              AR
            </a>{" "}
          </BodyText>
        </Box>
      </Wrapper>
    </FullHeightBox>
  );
}

export default Footer;
