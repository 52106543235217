import React from "react";
import { useLoadingState } from "./useLoading";

function useScrollAnimation(timeline) {
  const $scrollContainer = typeof window !== "undefined" ? window : undefined;
  const { isPageReady } = useLoadingState();

  const $container = React.useRef();
  const [reveal, setReveal] = React.useState();
  const _timeline = React.useRef();

  const check = React.useCallback(() => {
    const containerBB = $container.current.getBoundingClientRect();

    if (containerBB.top <= $scrollContainer.innerHeight / 2) {
      setReveal(true);
    }
  }, [$container.current, setReveal]);

  React.useEffect(() => {
    _timeline.current = timeline($container);
    _timeline.current.pause();
  }, [$container]);

  React.useLayoutEffect(() => {
    // if page is ready and it's not revealed already, check and add event listener
    if (isPageReady && !reveal) {
      check();
      $scrollContainer.addEventListener("scroll", check);
    }

    return () => {
      $scrollContainer.removeEventListener("scroll", check);
    };
  }, [isPageReady, $scrollContainer, $container]);

  React.useEffect(() => {
    if (reveal) {
      _timeline.current.play();
      $scrollContainer.removeEventListener("scroll", check);
    }
  }, [reveal, _timeline.current]);

  return $container;
}

export default useScrollAnimation;
