import styled, { css } from "styled-components";
import { space, color, layout, flexbox, position } from "styled-system";

const Box = styled.div`
    box-sizing: 'border-box';
    min-width: 0;

    position: relative;

    ${space}
    ${color}
    ${layout}
    ${flexbox}
    ${position}

    perspective: 500px;

  ${props =>
    props.full &&
    css`
      margin-left: -35px;
      margin-right: -35px;

      width: calc(100% + 70px);
      max-width: inherit;
    `}
    
`;

export default Box;
